import { useEffect, useState, useContext } from "react";
import {
  FormContainer, FormItem, StyledInput, PButton, StyledTextArea, EEAT,
  EEATWRAP,
  EEATText,
  StyledLabel,
  StyledSelect,
} from "./styled";
import { Row, Col, Form, Input } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Image from 'next/image';
import { jsonData } from "../../../components/LocationsMap/_data";
import { LocationContext } from "../../../context/LocationContext";

export default function FormConsultationSmall({
  handleSubmit,
  form,
  theme,
  loading,
  referralCode,
  showModal,
  setShowModal,
  submitButton,
  dispatchDestination,
  formId,
  darkLabels
}) {
  const [urlParams, setUrlParams] = useState(null);
  const [selectedShowroom, setSelectedShowroom] = useState(null);
  const [showroomData, setShowroomData] = useState(null);
  const location = useContext(LocationContext);

  // Filter showrooms from jsonData
  const showroomOptions = jsonData.features
    .filter(location => location.properties.showroom === 'yes')
    .map(showroom => ({
      value: `${showroom.properties.city}, ${showroom.properties.state}`,
      label: `${showroom.properties.city}, ${showroom.properties.state}`,
      data: showroom
    }));

  // Watch for location changes and update showroom selection
  useEffect(() => {
    if (location && !selectedShowroom) {
      const showroomValue = `${location.properties.city}, ${location.properties.state}`;
      const matchingShowroom = showroomOptions.find(option => 
        option.value === showroomValue
      );

      if (matchingShowroom) {
        console.log('Setting initial showroom from location:', matchingShowroom.data);
        setSelectedShowroom(showroomValue);
        setShowroomData(matchingShowroom.data);
        
        form.setFieldsValue({
          showroom: showroomValue,
          tenantId: matchingShowroom.data.properties.tenantId,
          city: matchingShowroom.data.properties.city,
          state: matchingShowroom.data.properties.state
        });
      }
    }
  }, [location, form, selectedShowroom]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
    }

    return () => {
      setUrlParams(null);
    };
  }, []);

  const handleShowroomChange = (value) => {
    const selectedOption = showroomOptions.find(option => option.value === value);
    if (selectedOption) {
      console.log('Selected showroom:', selectedOption.data);
      setSelectedShowroom(value);
      setShowroomData(selectedOption.data);
      
      form.setFieldsValue({ 
        showroom: value,
        tenantId: selectedOption.data.properties.tenantId,
        city: selectedOption.data.properties.city,
        state: selectedOption.data.properties.state
      });
    }
  };

  const onFinish = (values) => {
    const selectedOption = showroomOptions.find(option => option.value === values.showroom);
    
    if (!selectedOption) {
      console.error('No showroom selected');
      return;
    }

    // Create submission data object
    const submissionData = {
      ...values,
      ...urlParams,
      city: selectedOption.data.properties.city,
      state: selectedOption.data.properties.state,
      tenantId: selectedOption.data.properties.tenantId
    };

    console.log('Form values:', values);
    console.log('Selected showroom:', selectedOption.data);
    console.log('Submission data:', submissionData);

    handleSubmit(submissionData);
  };

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer 
        name={formId} 
        size="small" 
        onFinish={onFinish}
        form={form}
        layout="vertical"
        darkLabels={darkLabels}
      >
        <FormItem
          name="full_name"
          rules={[{ required: true, message: "Full Name required" }]}
          label={<StyledLabel>Full Name</StyledLabel>}
        >
          <StyledInput placeholder="Jane/John Doe" />
        </FormItem>
        <FormItem
          name="email"
          rules={[
            { required: true, message: "Email required" },
            { type: "email", message: "Not a valid email" }
          ]}
          label={<StyledLabel>Email Address</StyledLabel>}
        >
          <StyledInput placeholder="user@email.com" />
        </FormItem>
        <FormItem
          name="phone"
          rules={[{ required: true, message: "Phone required" }]}
          label={<StyledLabel>Phone Number</StyledLabel>}
          normalize={(value) => {
            if (!value) return value;
            const onlyNums = value.replace(/[^\d]/g, "");
            if (onlyNums.length >= 4 && onlyNums.length <= 6) {
              return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
            }
            if (onlyNums.length > 6 && onlyNums.length <= 10) {
              return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
            }
            if (onlyNums.length > 10) {
              return `(${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 11)}`;
            }
            return onlyNums;
          }}
        >
          <StyledInput placeholder="202-555-5555" />
        </FormItem>
        <FormItem
          name="showroom"
          rules={[{ required: true, message: "Please select a showroom" }]}
          label={<StyledLabel>Nearest Showroom</StyledLabel>}
        >
          <StyledSelect
            placeholder="Select Showroom"
            options={showroomOptions}
            onChange={handleShowroomChange}
          />
        </FormItem>
        <FormItem name="tenantId" hidden>
          <Input type="hidden" />
        </FormItem>
        <FormItem name="city" hidden>
          <Input type="hidden" />
        </FormItem>
        <FormItem name="state" hidden>
          <Input type="hidden" />
        </FormItem>
        <FormItem
          name="message"
          label={<StyledLabel>Comments</StyledLabel>}
        >
          <StyledTextArea
            placeholder="Comments/Promo Code"
            rows={2}
          />
        </FormItem>
        <FormItem>
          <PButton
            data-cy="submit"
            ptype="primary"
            pname={submitButton || "GET STARTED"}
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
          />
        </FormItem>
        <EEATWRAP>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-01.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="40+ years experience"
            >
            </Image>
            <EEATText>40+ Years<br />Experience</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-02.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="limited lifetime warranty"
            >
            </Image>
            <EEATText>Limited<br /> Lifetime Warranty</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-03.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="a+ bbb rating"
            >
            </Image>
            <EEATText>A+ BBB<br />Rating</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-04.svg"
              width="40px"
              height="52px"
              className="iconImage"
              alt="custom fit"
            >
            </Image>
            <EEATText>Custom<br />Fit</EEATText>
          </EEAT>
        </EEATWRAP>
      </FormContainer>
      <Loading loading={loading} />
    </div>
  );
}
