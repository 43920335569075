import { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import { setGoogleAdwordsInfo } from "../../../utils/helper";
import { getQueryFromStorage } from "../../../hooks/useQueryCatcher";
import { useCookies } from "react-cookie";
import useFetch from "../../../../ClassyKitchen/hooks/useFetch";
const EMAIL_URL = "/api/contact/email";
const CRM_URL = "/api/contact";
import TagManager from "react-gtm-module";

const FormConsultation = ({ theme, variant, referralCode, submitButton, dispatchDestination = "contact", formId, darkLabels, handleSubmit: parentHandleSubmit, loading: parentLoading, form: parentForm, showModal: parentShowModal, setShowModal: parentSetShowModal }) => {
  const { submitMultiple } = useFetch();
  const [cookie, setCookie] = useCookies(["converted"]);
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    if (parentHandleSubmit) {
      const valuesWithClient = {
        ...values,
        client: 'classyclosets'
      };
      return parentHandleSubmit(valuesWithClient);
    }

    setLoading(true);
    let url = typeof window !== "undefined" ? window.location.href : "null";
    
    const nameParts = values.full_name?.split(' ') || [];
    const firstName = nameParts[0] || '';
    const lastName = nameParts.slice(1).join(' ') || '';
    
    const baseData = {
      ...values,
      first_name: firstName,
      last_name: lastName,
      client: 'classyclosets',
      queries: getQueryFromStorage(),
      user_id: window.uniqueVisitorId,
      visitorId: window.uniqueVisitorId,
      confidenceScore: window.confidenceScore,
      fingerprint: window.uniqueVisitorId
    };

    console.log('Form submission data:', baseData);

    setGoogleAdwordsInfo(values);
    TagManager.dataLayer({
      dataLayer: {
        crmOrClassic: "classic",
      },
    });

    try {
      const requests = [
        {
          url: EMAIL_URL,
          method: "POST",
          data: baseData
        },
        {
          url: CRM_URL,
          method: "POST",
          data: baseData
        }
      ];

      await submitMultiple(requests);

      setCookie("converted", "step_1", {
        path: "/",
        maxAge: 86400 * 7 // 7 days
      });

      setTimeout(() => {
        form.resetFields();
        setShowModal(true);
        setLoading(false);
      }, 300);
    } catch (error) {
      console.error('Form submission error:', error);
      console.error('Form data:', baseData);
      setLoading(false);
    }
  };

  if (variant === "large")
    return (
      <FormConsultationLarge
        handleSubmit={handleSubmit}
        loading={parentLoading || loading}
        form={parentForm || form}
        theme={theme}
        referralCode={referralCode}
        showModal={parentShowModal || showModal}
        setShowModal={parentSetShowModal || setShowModal}
        submitButton={submitButton}
        dispatchDestination={dispatchDestination}
        formId={formId}
        darkLabels={darkLabels}
      />
    );

  return (
    <FormConsultationSmall
      handleSubmit={handleSubmit}
      loading={parentLoading || loading}
      form={parentForm || form}
      theme={theme}
      referralCode={referralCode}
      showModal={parentShowModal || showModal}
      setShowModal={parentSetShowModal || setShowModal}
      submitButton={submitButton}
      dispatchDestination={dispatchDestination}
      formId={formId}
      darkLabels={darkLabels}
    />
  );
};

FormConsultation.propTypes = {
  theme: PropTypes.object,
  variant: PropTypes.string,
  referralCode: PropTypes.string,
  submitButton: PropTypes.string,
  dispatchDestination: PropTypes.string,
  id: PropTypes.string,
  darkLabels: PropTypes.bool,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  form: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default withTheme(FormConsultation);
