import styled, { css } from "styled-components";
import { Form, Input, Select } from "antd";
const { TextArea } = Input;
import _PButton from "../../../components/PButton";

export const StyledLabel = styled.label`
  color: white;
  font-size: 10px;
  display: block;
  margin-bottom: 0px;
  line-height: 0.6em;

  div.dark-labels & {
    color: black;
  }
`;

export const PButton = styled(_PButton)`
  border-radius: 0px;
  background-color: white;
  padding: 10px !important;
  height: unset;
  box-shadow: none;
  margin-top:2px;
  border: 2px solid ${({ theme }) => theme.colors.classyRed};

  span {
    color: ${({ theme }) => theme.colors.classyRed};
    font-weight: bold;
    font-size: 1.25rem;
    font-family: "Roboto Bold"
  }


`;

export const FormDiv = styled.div`
  flex: 1 1 0px;
  min-height: fit-content;
  background-color: white;
  padding: 0.2em;
  border: 0.1rem solid black;
  .form {
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
    display: block;
    width: 100%;
    padding: 10%;
    position: relative;
  }
`;

export const FormContainer = styled(Form)`
  && {
    .ant-form-item {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
    
    .ant-form {
      .ant-form-item {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }
    }

    .ant-form-item-required::before {
      display: none !important;
    }

    .ant-form-item-required::after {
      display: inline-block !important;
      margin-left: 4px;
      color: white !important;
      font-size: 0.8rem;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*' !important;
    }
  }
  
  .background-red {
    .ant-form-item-explain {
      color: white;
      font-weight: 500;
      margin-top: 0px;
      line-height: 1;
    }
  }
  .ant-form-item-label {
    padding: 0;
    line-height: 1;
  }
  .ant-form-item-explain {
    min-height: 12px;
    font-size: 0.7rem;
  }
`;

export const StyledTextArea = styled(TextArea)`
min-width: 0;
  border: 1px solid #000;
  border-radius: 0px;
  padding:5px 10px;
  min-height:87px !important;
  margin: 0 0 4px 0;
 `;

export const FormItem = styled(Form.Item)`
  margin-bottom: 2px;
`;

export const StyledInput = styled(Input)`
  min-width: 0;
  border: 1px solid #000;
  border-radius: 0px;
  padding: 5px 10px;
  height: 32px;
  margin: 0;

  ${({ $primary, theme }) =>
    $primary &&
    css`
      color: ${theme.colors.darkGray2};
      font-weight: 700;
    `}
`;

export const StyledSelect = styled(Select)`
  min-width: 0;
  border: 1px solid #000;
  margin: 0;
  
  .ant-select-selector {
    height: 31px !important;
    padding: 0 10px !important;
  }
  .ant-select-selection-item {
    line-height: 26px !important;
    font-size: 1rem;
    margin: auto;
  }
  .ant-select-arrow {
    color: rgba(0, 0, 0, 1) !important;
  }
  .ant-select-selection-placeholder {
    font-size: 1.1rem;
    line-height: 26px !important;
    margin: auto;
  }
`;

export const FormHeadingSection = styled.div`
  text-align: center;

  & > h2 {
    font-size: 1.3rem;
    color: #762738;
    font-family: "Montserrat";
    font-weight: bold;
  }
  span {
    text-decoration: underline;
  }
  p {
    font-family: "Montserrat";
    font-size: 1rem;
  }
`;
export const EEATText = styled.span`
color:#fff;
text-align:center;
font-size:0.85REM;
line-height:1.2;

`;

export const EEATWRAP = styled.div`

  display:flex;
  margin:8px 4% 8px 4%;
  @media only screen and (max-width: 600px) {}
`;

export const EEAT = styled.div`

  display:flex;
  color:white;
  flex-direction:column;
  row-gap:0.4em;
  padding:0px;
  margin:0;
  flex:1;

  .iconImage{
    width:52px !important;
    height:64px !important;
  

    color:white !important;

    margin-top:8px;
  }

`;
